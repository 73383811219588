import React, { useContext } from 'react'

import { IImage } from '../types/index'
import { AppContext } from './ContextProvider'
import { getCaption } from '../utils'

const Image: React.FC<IImage> = ({ className, image}) => {

    const context = useContext(AppContext)

    /*
    const showImageModal = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        // if event argument, first call preventDefault - see https://reactjs.org/docs/handling-events.html
        e.preventDefault()
        // further logic
    }
    */

    const caption = getCaption(image)

    let onClick
    // (!) YAML bool prop 'modal' test: false when 'modal: False' OR prop absent; true when 'modal: True'
    const logPrefix = '# Image - translated caption['+caption+'] - image .src['+image.src+'] .modal['+image.modal+'] '
    if(image.modal) {
        // console.log(logPrefix + 'checks TRUE')
        onClick = () => { context.setImageModal({ src: image.src, caption: !!caption ? caption : '' }) }
    }
    else {
        // console.log(logPrefix + 'checks FALSE')
        onClick = () => { }
    }

    return (
        <img className={!className ? 'responsive-image' : className} alt={''} src={image.src} onClick={onClick}/>
    )
}

export default Image
