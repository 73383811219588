import React from 'react'

// import Layout from '@components/Layout'
import Layout from '../components/Layout'
import Image from '../components/Image'
import { IEvent } from '../types/index'
import { t } from '../components/Translator'
// @ts-ignore
import content from '../../content/concerts.yaml'

const addBreak = (caption) => {
  if(!caption.includes('©')) return caption
  const ps = caption.split('©')
  return `${ps[0]}<br/>© ${ps[1]}`
}

const EventRow: React.FC<IEvent> = ({ event }) => {

  // console.log('event', event)

    const { day_of_week, day, month, hour, title, abstract, fee, images } = event

    return (
      // (!) Bootstrap: Extra small <576px; Small ≥576px; Medium ≥768px; Large ≥992px; Extra large ≥1200px
      <div className="event-row">
        <div className="col-md event">
          <div className="datetime">
            <span className="day-of-week">{t(day_of_week)}</span>
            <span className="day">{day}</span>
            <span className="month">{t(month)}</span>
            <span className="time">{t(hour)}</span>
          </div>
          <div className="vertical-line"/>
          <div className="act">
            <span className="title" dangerouslySetInnerHTML={{ __html: addBreak(t(title)) }}/>
            <span className="abstract" dangerouslySetInnerHTML={{ __html: t(abstract) }}/>
            {fee && fee !== 'gratis' &&
              <span className="ticket"><i className="fa fa-ticket" style={{marginRight: '8px'}}/>{fee}</span>
            }
          </div>
        </div>
        <div className="col-md images">
          {images.map((image, imageIdx) =>
            <div key={imageIdx} className={`image count-${imageIdx}`}>
              <Image image={image}/>
              <span dangerouslySetInnerHTML={{ __html: addBreak(t(image.caption)) }}/>
            </div>
          )}
        </div>
      </div>
    )
}

const EventsPage: React.FC = () => {

    const logos = content.logos
    const title = content.title
    const infoPhone = content.info_phone
    const tickets = content.tickets
    const director = content.director
    const subscription = content.subscription

    const titleSpring = content.title_spring
    const eventsSpring = content.events_spring

    const titleSummer = content.title_summer
    const eventsSummer = content.events_summer

    const titleAutumn = content.title_autumn
    const eventsAutumn = content.events_autumn

    // original (late 2020) 'Vestige' html website design see:
    //  D:\ARCHIVE_projects\SSK\20210218_ssk-html-site\ssk-evenementen.html
    //  if address ever required: it contains link fa fa-map-marker
    // ONLINE EXAMPLES
    // https://www.prepbootstrap.com/bootstrap-template/event-calendar
    // https://www.bootdey.com/snippets/tagged/event
    // (!) https://bootsnipp.com/snippets/kMRrW

    return (
        <Layout head={content.head} banner={content.banner}>

          <div className="spacer-10"/>

          <h1 dangerouslySetInnerHTML={{ __html: t(title) }}/>
          {/*<h2 style={{color:'#000000'}}>{t(info.reservation)}</h2>*/}

          <div className="spacer-20"/>

          <div className="event-row" style={{marginBottom:'0px'}}>
            <div className="col-md-5" style={{padding:'15px'}}>
              <div style={{display:'flex'}}>
                <h3>INFO</h3>
                <span style={{marginLeft:'15px', marginTop:'1px'}}><i className="fa fa-phone"/>{infoPhone}</span>
              </div>
              <h3 style={{marginTop:'5px'}}>TICKETS</h3>
              <h3 style={{marginTop:'5px'}}>{t(tickets.name)}</h3>
              <div style={{marginTop:'-4px'}}>
                <span><i className="fa fa-map-marker"/>{t(tickets.address)}</span>
              </div>
              <div style={{margin:'7px 10px 0 0'}}>
                <span><i className="abstract"/>{t(tickets.availability)}</span>
              </div>
              {/*<div style={{marginTop:'3px'}}>*/}
              {/*  <span><i className="fa fa-phone"/>{tickets.phone}</span>*/}
              {/*</div>*/}
            </div>
            <div className="col-md-7 general-info">
              <h3>{t(director.title)}</h3>
              <span>{director.name}</span>
              <div style={{marginTop:'10px'}}>
                <span><a href={`https://${director.website}`} target="_blank">{director.website}</a></span>
              </div>
              <div style={{marginTop:'5px'}}>
                <span><i className="fa fa-envelope"/>
                  <a href={`mailto:${director.email}`}>{director.email}</a>
                </span>
              </div>
            </div>
          </div>

          <div className="event-row">
            <div className="col-md-12 general-info">
              <h3>{t(subscription.title)}</h3>
              <span className="abstract" dangerouslySetInnerHTML={{ __html: t(subscription.abstract) }}/>
              <div style={{marginTop:'5px'}}>
              <span><i className="fa fa-envelope"/>
                <a href={`mailto:${subscription.email}`}>{subscription.email}</a>
              </span>
              </div>
            </div>
          </div>

          <div className="spacer-30"/>
          <h2 dangerouslySetInnerHTML={{ __html: t(titleSpring) }}/>
          <div className="spacer-10"/>
          {eventsSpring.map((event, eventIdx) =>
            <EventRow key={eventIdx} event={event}/>
          )}

          <div className="spacer-40"/>
          <h2 dangerouslySetInnerHTML={{ __html: t(titleSummer) }}/>
          <div className="spacer-10"/>
          {eventsSummer.map((event, eventIdx) =>
            <EventRow key={eventIdx} event={event}/>
          )}

          <div className="spacer-40"/>
          <h2 dangerouslySetInnerHTML={{ __html: t(titleAutumn) }}/>
          <div className="spacer-10"/>
          {eventsAutumn.map((event, eventIdx) =>
            <EventRow key={eventIdx} event={event}/>
          )}

          <div className="spacer-50"></div>

            {/*TODO iterate logos collection*/}
          <div className="row">
              <div className="col-2">
                  <Image className='logo-image' image={logos[0]}/>
              </div>
              <div className="col-2">
                  <Image className='logo-image' image={logos[1]}/>
              </div>
              <div className="col-2">
                  <Image className='logo-image' image={logos[2]}/>
              </div>
              <div className="col-2">
                  <Image className='logo-image' image={logos[3]}/>
              </div>
              <div className="col-3">
                  <Image image={logos[4]}/>
              </div>
              {/*<div className="col-3">*/}
              {/*    <Image image={logos[4]}/>*/}
              {/*    <div className="spacer-20"></div>*/}
              {/*    <Image image={logos[5]}/>*/}
              {/*</div>*/}
          </div>

        </Layout>
    )
}


export default EventsPage